<template>
  <div class="church-ministry template-1">
    <header class="page-header container">
      <h1>{{ this.gideonSubcategoryPageInfo.label_page_title }}</h1>
    </header>
    <b-container class="top">
      <b-row class="top-row">
        <b-col sm="12" xs="12" class="top-col mb-5">
          <top-nav-search class="top-nav-search" :showDescriptionText="showDescriptionText"
            :backLabel="gideonSubcategoryPageInfo.label_back_description" :showMerchTotal="showMerchTotal"
            :i18n="translations.components">
          </top-nav-search>
          <camp-selector @camp_changed="page_load($event)" :onlyUSA="onlyUSA" :tooltip="translations.tcCurrentHome"
            class="mt-3 mb-1" v-if="this.categories[0].CategoryName === 'Scripture'" :i18n="translations.components">
          </camp-selector>
        </b-col>
      </b-row>
      <b-row class="tile-row">
        <b-col sm="12" xs="12" class="tile-col-title mb-5">
          <div class="title">
            <h4>{{ this.gideonSubcategoryPageInfo.name }}</h4>
          </div>
          <div class="category-ddl">
            <b-form-select id="subcategory-select" name="subcategory-select" title="Subcategory"
              v-model="selectedSubcategory" :options="subcategoryOptions"></b-form-select>
            <b-button @click.stop="handleChangeSubcategory" variant="primary" class="ml-2 btn-go">
              {{ this.gideonSubcategoryPageInfo.button_go }}
            </b-button>
          </div>
        </b-col>
        <b-col sm="4" xs="12" class="tile-col mb-5" v-for="(obj, index) in gideonStoreProducts" :key="index">
          <card :itemId="obj.ItemID" :prd_key="obj.prd_key" :prd_code="obj.prd_code"
            :prd_image_file_path="obj.prd_image_file_path" :prd_display_price="obj.prd_display_price"
            :prd_name="obj.prd_name" :prd_show_out_of_stock="obj.prd_show_out_of_stock === 'True' ? true : false"
            :label_product_not_available="gideonSubcategoryPageInfo.label_product_not_available"
            :btnAddToCart="translations.tcAddToCart" :labelPrice="gideonSubcategoryPageInfo.label_price"
            :labelItem="gideonSubcategoryPageInfo.label_item"
            @set_item="addProductToCart($event.prd_key, $event.quantity)" @set_product="setProductAndRedirect($event)">
          </card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import Card from './CardProductList.vue'
import TopNavSearch from './TopNavSearch.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'store-category-products',
  mixins: [translationMixin],
  data() {
    return {
      onlyUSA: true,
      selectedSubcategory: null,
      showDescriptionText: true,
      storeNavigationPagPayload: {
        lang: 'en', // default to english for now
        landingpage: 'Store_Navigation',
      },
      storePagePayload: {
        lang: 'en', // default to english for now
        subcategoryid: '',
      },
      translations: {},
    }
  },
  components: {
    topNavSearch: TopNavSearch,
    card: Card,
    campSelector: CampSelect,
  },
  methods: {
    ...mapActions({
      loadStoreNavigationPage: 'gideonStore/loadStoreNavigationPage',
      loadStoreSubcategoryPage: 'gideonStore/loadStoreSubcategoryPage',
      setLoadingStatus: 'menu/setLoadingStatus',
      setStoreSelectedProductId: 'gideonStore/setStoreSelectedProductId',
      setStoreSelectedProductKey: 'gideonStore/setStoreSelectedProductKey',
      setStoreSelectedSubCategoryId: 'gideonStore/setStoreSelectedSubCategoryId',
      updateStoreCart: 'gideonStore/updateStoreCart',
    }),
    async page_load(evt) {
      if (!evt) {
        try {
          this.setLoadingStatus(true)
          this.storeNavigationPagPayload.lang = this.prefCulture
          this.storePagePayload.lang = this.prefCulture
          this.storePagePayload.subcategoryid = this.gideonStoreSelectedSubcategoryId

          await Promise.all([
            await this.loadStoreSubcategoryPage(this.storePagePayload),
            (this.selectedSubcategory = await this.gideonSubcategoryPageInfo.itemId),
            await this.loadStoreNavigationPage(this.storeNavigationPagPayload),
          ])
        } catch (e) {
          console.error(e)
        } finally {
          this.setLoadingStatus(false)
        }
      }
    },
    async handleChangeSubcategory() {
      this.setStoreSelectedSubCategoryId(this.selectedSubcategory)
      await this.page_load()
    },
    setProductAndRedirect(data) {
      this.setStoreSelectedProductId(data.itemId)
      this.setStoreSelectedProductKey(data.prd_key)
      this.$router.push({ name: 'store-product-details' })
    },
    async addProductToCart(prd_key, quantity) {
      let cartPayload = {
        prd_key: prd_key,
        quantity: quantity,
      }
      let response = false
      await Promise.all([
        (response = await this.updateStoreCart(cartPayload)),
        await this.loadStoreNavigationPage(this.storeNavigationPagPayload),
      ]).then(() => {
        this.setLoadingStatus(false)
      })
      if (response === false) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcErrorAddingItemToCart,
          confirmButtonText: this.translations.tcOk,
        }).then((result) => {
          return
        })
      } else {
        this.$swal({
          icon: 'success',
          text: this.translations.tcItemAddedToCart,
          showConfirmButton: true,
          showDenyButton: true,
          confirmButtonText: this.translations.tcOk,
          denyButtonText: 'Go to Cart'
        }).then((result) => {
          if (result.isDenied) {
            this.$router.push({ name: 'store-cart' })
          }
          return
        })
      }
    },
  },
  computed: {
    ...mapGetters({
      categories: 'gideonStore/gideonStoreCategories',
      demograph: 'user/demograph',
      gideonStoreCategories: 'gideonStore/gideonStoreCategories',
      gideonStoreProducts: 'gideonStore/gideonStoreProducts',
      gideonStoreSelectedSubcategory: 'gideonStore/gideonStoreSelectedSubcategory',
      gideonStoreSelectedSubcategoryId: 'gideonStore/gideonStoreSelectedSubcategoryId',
      gideonSubcategoryPageInfo: 'gideonStore/gideonSubcategoryPageInfo',
      prefCulture: 'user/userPreferredCulture',
      userCampKey: 'user/userCampKey',
    }),
    subcategoryOptions() {
      let options = []
      if (
        this.categories.length > 0 &&
        this.categories[0].Subcategories &&
        this.categories[0].Subcategories.length > 0
      ) {
        let subCats = this.categories[0].Subcategories
        options = subCats.map((x) => {
          return {
            text: x.SubcategoryName,
            value: x.SubcategoryId,
          }
        })
      }
      return options
    },
    showMerchTotal() {
      let smt = false
      if (this.demograph.camp_key === this.userCampKey || this.categories[0].CategoryName === 'Merchandise') {
        smt = true
      }
      return smt
    },
  },
  async created() {
    await Promise.all([
      this.getViewTranslations(),
      this.getComponentTranslations('camp-select', 'store-topnav-search')])
      .then((results) => {
        const componentTranslations = results[1]
        this.$set(this.translations, 'components', componentTranslations)
      })
    const subcat_key = this.$route.params.subcat_key
    if (subcat_key) {
      this.setStoreSelectedSubCategoryId(subcat_key)
      this.$router.replace('/resources/store/products')
    } else if (!!this.gideonStoreSelectedSubcategoryId) {
      this.setStoreSelectedSubCategoryId(this.gideonStoreSelectedSubcategoryId)
    }
    await this.page_load()
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';

.page-header {
  p {
    text-align: center;
    margin-top: 20px;
  }
}

.top-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  justify-content: center;

  .top-col {
    .top-nav-search {
      width: 100%;
    }
  }
}

.text-1 {
  text-align: center;
  width: 70%;
  margin: 0 auto;
}

.text-2 {
  text-align: center;
  color: $secondary-color;
  width: 70%;
  margin: 0 auto;
}

.switch-buttons {
  margin: 0 auto;
  text-align: center;

  .merchscriptBtn {
    background-color: #747474;
  }

  .merchscriptBtnSelected {
    background-color: $secondary-color;
  }
}

.tile-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: stretch;
  background-color: #ffffff;
  padding-top: 15px;
  margin-bottom: 25px;
  border: 1px solid #e9e9e9;
  box-shadow: 0 2px 10px 3px rgba(0, 0, 0, 0.2) !important;

  .tile-col-title {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    justify-content: space-between;

    .category-ddl {
      display: flex;
      flex-direction: row;
      align-content: flex-end;
      justify-content: space-between;

      .btn-go {
        padding: 8px;
      }
    }
  }

  .tile-col {
    display: flex;
    flex-direction: row;
    align-content: stretch;
    justify-content: space-between;

    .tile-col-card {
      // flex-grow: 2;
      // .icon {
      //   min-height: 75px;
      // }
    }
  }
}

.tiles {
  margin-bottom: 80px;
}
</style>
